
import { Vue, Options } from 'vue-class-component';
import { BaseButton, PageLoading, PdfViewer } from '@/lib/components';
import { PatientDocumentService } from '@/services/api';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    patientId: {
      type: String,
      required: true
    },

    documentId: {
      type: String,
      required: true
    },

    back: {
      type: Function,
      required: true
    }
  },
  components: {
    BaseButton,
    PageLoading,
    PdfViewer
  }
})
export default class Confirmation extends Vue {
  patientId!: string;
  documentId!: string;
  pdf = '';
  patientDocumentService = new PatientDocumentService(this.patientId);
  notificationStore = useNotificationStore();

  async mounted() {
    try {
      await this.getPdf();
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.fetch-data')
      });
    }
  }

  async getPdf() {
    const response = await this.patientDocumentService.fetchBase64Pdf(this.documentId);

    this.pdf = `data:application/pdf;base64,${response}`;
  }
}
