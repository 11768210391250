import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-16 flex flex-col h-full" }
const _hoisted_2 = { class: "mb-12 flex items-center justify-between" }
const _hoisted_3 = { class: "text-gray-900 font-bold text-2xl" }
const _hoisted_4 = { class: "mt-2 text-gray-500 font-regular text-lg" }
const _hoisted_5 = {
  key: 1,
  class: "p-10 flex items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PdfViewer = _resolveComponent("PdfViewer")!
  const _component_PageLoading = _resolveComponent("PageLoading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('platform.common.confirmation')), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('custom.uhb.correspondence.confirm-instructions')), 1)
      ]),
      _createVNode(_component_BaseButton, {
        color: "ghost",
        "left-icon": "edit",
        size: "small",
        onClick: _ctx.back
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('custom.uhb.correspondence.edit-letter')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    (_ctx.pdf)
      ? (_openBlock(), _createBlock(_component_PdfViewer, {
          key: 0,
          pdf: _ctx.pdf,
          class: "flex-1"
        }, null, 8, ["pdf"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_PageLoading)
        ]))
  ]))
}